<template>
  <v-card class="ma-2">
    <v-card-title class="pa-4 pb-3">
      <v-flex lg4 class="d-flex">
        <v-autocomplete v-model="filterObj.shift" :items="shiftItems" item-text="name" item-value="_id" clearable hide-details style="max-width: 250px" dense outlined label="Shift"></v-autocomplete>
        <!-- <v-autocomplete hide-details style="max-width: 250px" dense outlined label="Staff" prepend-inner-icon="mdi-account"
          v-model="filterObj.staff" class="mx-1" clearable :items="staffItems" item-text="formdata.name" item-value="_id" return-object></v-autocomplete> -->
      </v-flex>
      <!-- <span class="body-1 font-weight-medium px-2"> Staff Attendance </span> -->
      <v-spacer></v-spacer>
      <v-flex lg3 class="d-inline-flex justify-end">
        <v-btn icon dark small @click="goPrevious" class="mt-1" color="black" title="Previous day">
          <v-icon dark small> mdi-arrow-left </v-icon>
        </v-btn>
        <v-menu v-model="filterObj.Menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
          <template #activator="{ on, attrs }">
            <v-text-field style="max-width:130px" v-model="filterObj.date" class="mx-2" hide-details
            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
          </template>
          <v-date-picker v-model="filterObj.datePicker" @input="filterObj.Menu = false" :max="currentDate"
            @change="getAttendanceObj(null); filterObj.date = $_formatDate(filterObj.datePicker, 'YYYY-MM-DD', 'DD.MM.YYYY') "></v-date-picker>
        </v-menu>
        <v-btn icon dark small @click="goNext" color="black" class="mt-1" title="Next day">
          <v-icon dark small> mdi-arrow-right </v-icon>
        </v-btn>
      </v-flex>
      <!-- <v-flex lg1 class="d-flex justify-end">
        <v-btn icon class="mx-2" @click="$router.push('/attendance/manage')">
          <v-icon> mdi-cog </v-icon>
        </v-btn>
      </v-flex> -->
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pa-0 overflow-y-auto" style="max-height:61vh">
      <v-simple-table>
        <thead>
          <tr>
            <th style="width: 15%"> Staff </th>
            <th style="width: 8%"> Shift </th>
            <th style="width: 45%" class="attendanceTypeRadio">
              First - Second 
            </th>
            <th style="width: 10%">Status</th>
            <th style="width: 10%">Note</th>
            <th style="width: 10%"> Actions </th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th class="attendanceTypeRadio">
              <div class="d-flex">
                <v-radio-group v-model="attendanceObj.status" hide-details class="ma-0" row  @change="invertValue(-1, 'status', attendanceObj.status)">
                  <v-radio  v-for="(i, index) in attendanceTypes" :key="index" :value="i._id">
                    <template #label>
                      <div><strong :style="`color: ${i.color|| '#1976d2'}`">{{i.name}}</strong></div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <template  v-if="attendanceObj.daySplited">
                  <v-divider vertical class="mr-2"></v-divider>
                  <v-radio-group v-model="attendanceObj.status2" hide-details class="ma-0" row @change="invertValue(-1, 'status2', attendanceObj.status2)">
                    <v-radio v-for="(i, index) in attendanceTypes" :key="index" :value="i._id">
                      <template #label>
                        <div><strong :style="`color: ${i.color|| '#1976d2'}`">{{i.name}}</strong></div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </template>
              </div>
            </th>
            <th></th>
            <th></th>
            <th>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="attendanceObj.daySplited ? 'success darken-2' : 'grey'" dark v-bind="attrs" v-on="on" @click="invertValue(-1, 'daySplited')">
                    mdi-call-split 
                  </v-icon>
                </template>
                <span>{{attendanceObj.daySplited ? 'Merge Day' : 'Split Day'}}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-2" color="orange" dark v-bind="attrs" v-on="on" @click="invertValue(-1, 'status', null, true)">
                    mdi-restore
                  </v-icon>
                </template>
                <span>Reset</span>
              </v-tooltip>
            </th>
            <th class="text-right">
              <v-btn icon small @click="invertValue(-1, 'show_logs')">
                <v-icon>{{attendanceObj.show_logs ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline'}}</v-icon>
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(user, index) in attendanceObj.attendance">
            <tr :key="index" v-if="filterObj.shift ? (user.shift === filterObj.shift) : true">
              <td>
                <span>
                  <v-avatar size="28">
                  <v-img :src="`${imageRootUrl}profile/${user.profile}`"></v-img>
                  </v-avatar>
                </span>
                <span class="mx-3 font-weight-black"> {{user.formdata.name}} </span>
              </td>
              <td>
                <v-autocomplete :items="shiftItems" item-text="name" item-value="_id" hide-details v-model="user.shift"></v-autocomplete>
              </td>
              <td class="attendanceTypeRadio">
                <div class="d-flex">
                  <v-radio-group v-model="user.status" hide-details class="ma-0" row>
                    <v-radio v-for="(i, index) in attendanceTypes" :key="index" :value="i._id">
                      <template #label>
                        <div><strong :style="`color: ${i.color|| '#1976d2'}`">{{i.name}}</strong></div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                  <template  v-if="user.daySplited">
                    <v-divider vertical class="mr-2"></v-divider>
                    <v-radio-group v-model="user.status2" hide-details class="ma-0" row>
                      <v-radio v-for="(i, index) in attendanceTypes" :key="index" :value="i._id">
                        <template #label>
                          <div><strong :style="`color: ${i.color|| '#1976d2'}`">{{i.name}}</strong></div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </template>
                </div>
              </td>
              <td>
                <v-chip v-if="user.leaveObj" outlined label color="orange" class="mr-2">Applied For Leave</v-chip>
              </td>
              <td class="pa-0">
                <input type="text" style="height: 100%; width: 100%" v-model="user.note">
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :color="user.daySplited ? 'success darken-2' : 'grey'" dark v-bind="attrs" v-on="on" @click="invertValue(index, 'daySplited')">
                      mdi-call-split 
                    </v-icon>
                  </template>
                  <span>{{user.daySplited ? 'Merge Day' : 'Split Day'}}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mx-2" color="orange" dark v-bind="attrs" v-on="on" @click="invertValue(index, 'status', null, true)">
                      mdi-restore
                    </v-icon>
                  </template>
                  <span>Reset</span>
                </v-tooltip>
              </td>
              <td class="text-right">
                <v-btn icon small @click="invertValue(index, 'show_logs')">
                  <v-icon>{{user.show_logs ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline'}}</v-icon>
                </v-btn>
              </td>
            </tr>
            <template v-if="user.show_logs">
              <tr :key="`logs_${index}`">
                <td colspan="2"></td>
                <td>
                  <table style="width: 75%">
                    <tr :key="`logtitle_${index}`">
                      <td>In Time</td>
                      <td>Out Time</td>
                      <td> 
                        <v-btn color="success" text fab dark x-small class="mt-1 pb-0" @click="addOrRemoveLogs(index, 'PUSH')">
                          <v-icon dark>mdi-plus-circle</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr v-for="(log, logIndex) in user.logs" class="border_bottom" :key="`logs_${index}_${logIndex}`">
                      <td>
                        <v-menu v-model="log.startTimePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :key="index">
                          <template #activator="{ on, attrs }">
                            <v-text-field hide-details v-model="log.clockinTime" :placeholder="$t('time')" dense outlined prepend-icon="mdi-clock" readonly v-bind="attrs"
                              v-on="on" class="pt-0"></v-text-field>
                          </template>
                          <v-time-picker format="24hr" v-model="log.clockinTime" @click:minute="log.startTimePicker= false"></v-time-picker>
                        </v-menu>
                      </td>
                      <td>
                        <v-menu v-model="log.endTimePicker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :key="index">
                          <template #activator="{ on, attrs }">
                            <v-text-field hide-details v-model="log.clockoutTime" :placeholder="$t('time')" dense outlined prepend-icon="mdi-clock" readonly v-bind="attrs"
                              v-on="on" class="pt-0"></v-text-field>
                          </template>
                          <v-time-picker format="24hr" v-model="log.clockoutTime" @click:minute="log.endTimePicker= false"></v-time-picker>
                        </v-menu>
                      </td>
                      <td>
                        <v-btn color="black" text fab dark x-small class="mt-1 pb-0" @click="addOrRemoveLogs(index, 'POP', logIndex)">
                          <v-icon dark>mdi-minus-circle</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </table>
                </td>
                <td colspan="4"></td>
              </tr>
            </template>
          </template>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :color="attendanceObj._id ? 'primary' : 'success'" class="text-capitalize" @click="saveOrUpdateAttendance"> Save </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-bottom-navigation :value="true" color="primary" horizontal fixed class="px-7">
      <v-layout row wrap align-center>
        <v-spacer></v-spacer>
        <v-flex lg3 class="d-flex justify-end align-center">
          <v-tooltip top v-for="(i, index) in exportOptions" :key="index">
            <template #activator="{ on, attrs }">
              <img :src="i.src" width="30" height="27" v-on="on" v-bind="attrs" class="cursor-pointer mx-2" @click="i.click()">
            </template>
            <span> {{i.toolTip}} </span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-bottom-navigation>
  </v-card>
</template>

<script>
import moment from 'moment'
export default {
  props: ['userObj', 'staffAttendaceItems'],
  data() {
    return {
      filterObj: {},
      attendanceItemConstructed: [],
      attendanceObj: {},
      staffItems: [],
      shiftItems: [],
      staffLeaveItems: [],
      attendanceTypes: [],
      currentDate: this.$moment().format('YYYY-MM-DD'),
      exportOptions: [
        {src: require('@/assets/icons/pdf.png'), toolTip: 'Export as PDF', click: () => this.generateReport('PDF') },
        {src: require('@/assets/icons/excel.png'), toolTip: 'Export as Excel', click: () => this.generateReport('EXCEL') },
        {src: require('@/assets/icons/gmail.svg'), toolTip: ' Share via Gmail', click: () => this.shareReport() }
      ]
    }
  },
  mounted () {
    this.getStaffList()
    this.getShiftsList()
    this.getAttendanceTypesList()
  },
  methods: {
    getAttendanceTypesList () {
      this.$_execute('get', 'attendance-types').then(({ data }) => {
        this.attendanceTypes = data
      })
    },
    getShiftsList () {
      this.$_execute('get', 'shifts').then(({ data }) => {
        this.shiftItems = data
      })
    },
    getStaffList () {
      this.$store.dispatch('getUsersList', { only: [ 2 ], lookupShift: true })
        .then((data) => {
          this.staffItems = data
          this.loadInitValues()
        })
    },
    getStaffsLeave ({ date = moment().format('YYYY-MM-DD') } = {}) {
      return new Promise((resolve) => {
        const model = {
          getAllStaffLeave: true,
          // userids: this.staffItems.map((user) => user._id),
          start: this.$_formatDate(date, 'YYYY-MM-DD', 'YYYY-MM-DDT00:00'),
          end: this.$_formatDate(date, 'YYYY-MM-DD', 'YYYY-MM-DDT23:59')
        }
        this.$_execute('post', 'leave/filter', model)
          .then(({ data }) => resolve(data))
      })
    },
    editStaffAttendance(model) {
      let modelObj = {}
      modelObj = this.$_copy({...model, date: this.userObj ? moment(model.date, 'DD.MM.YYYY').format('YYYY-MM-DD') : moment(this.filterObj.datePicker).format('YYYY-MM-DD')})
      modelObj.userObj = this.userObj ? this.userObj : model.userObj
      this.$emit("staffEdit", modelObj)
    },
    async getAttendanceObj () {
      if (this.filterObj.datePicker) this.staffLeaveItems = await this.getStaffsLeave({ date: this.filterObj.datePicker })
      const start = moment(this.filterObj.datePicker).format('YYYY-MM-DD')
      const end = moment(this.filterObj.datePicker).format('YYYY-MM-DD')
      const query = [{
        $set: {
          date: {
            $dateToString: {
              date: '$date', 
              format: '%G-%m-%d'
            }
          }
        }
      }, {
        $match: {
          date: { $gte: start, $lte: end }
        }
      }]
      this.$_execute("post", "staffAttendance/query", query).then(({ data }) => {
        let modelObj = null
        if (data && data.length) {
          modelObj = data[0]
          modelObj.dateFormatted = this.$_formatDate(modelObj.date, 'YYYY-MM-DD', 'DD.MM.YYYY')
          this.staffItems.forEach((x) => {
            let attendanceExists = modelObj.attendance.findIndex(y => y.user == x._id)
            if (attendanceExists >= 0) {
              modelObj.attendance.splice(attendanceExists, 1, { ...x, ...modelObj.attendance[attendanceExists] })
            } else {
              modelObj.attendance.push({ ...x, user: x._id, })
            }
          })
        } else {
          modelObj = {
            dateFormatted: this.filterObj.date,
            date: this.filterObj.datePicker,
            attendance: this.$_copy(this.staffItems),
          }
          modelObj.attendance.map(x => x.user = x._id)
        }
        modelObj.attendance.map(x => {
          x.leaveObj = this.staffLeaveItems.find(y => y.author === x._id)
          if (x.shift && typeof x.shift === 'object') x.shift = x.shift._id
          else {
            x.shit = this.shiftItems.find(y => y._id === x.shift)?._id
          }
        })
        this.attendanceObj = modelObj
      })
    },
    invertValue(index, key, value, resetAttendanceStatus) {
      const attendanceObj = this.$_copy(this.attendanceObj)
      if (index < 0) {
        // do for all
        if (resetAttendanceStatus) {
          attendanceObj.attendance.map(x => {
            x.status = null
            x.status2 = null
          })
        } else {
          attendanceObj[key] = value ? value : !attendanceObj[key] 
          attendanceObj.attendance.map(x => {
            x[key] = attendanceObj[key]
          })
        }
      } else {
        if (resetAttendanceStatus) {
          attendanceObj.attendance[index].status = null
          attendanceObj.attendance[index].status2 = null
        } else {
          attendanceObj.attendance[index][key] = !attendanceObj.attendance[index][key]
        }
      }
      this.attendanceObj = { ...attendanceObj }
    },
    addOrRemoveLogs(userIndex, type, logIndex) {
      if (!this.attendanceObj.attendance[userIndex].logs) this.attendanceObj.attendance[userIndex].logs = [] 
      if (type === 'PUSH') {
        this.attendanceObj.attendance[userIndex].logs.push({})
      } else if (type === 'POP') {
        this.attendanceObj.attendance[userIndex].logs.splice(logIndex, 1); 
      }
      this.attendanceObj.attendance = { ...this.attendanceObj.attendance }
    },
    saveOrUpdateAttendance () {
      let modelObj = this.$_copy(this.attendanceObj)
      const url = modelObj._id ?  `staffAttendance/${modelObj._id}` : 'staffAttendance';
      const method = modelObj._id ? 'put' : 'post';
      this.$_execute(method, url, modelObj).then(() => {
        this.getAttendanceObj()
      })
    },
    loadInitValues (momentObj = moment()) {
      this.filterObj.dateObj = momentObj
      if (this.userObj) {
        this.$set(this.filterObj, 'start', moment().subtract(1, 'months').format(`DD.MM.YYYY`))
        this.$set(this.filterObj, 'startDatePicker', moment().subtract(1, 'months').format(`YYYY-MM-DD`))
        this.$set(this.filterObj, 'end', moment().format('DD.MM.YYYY'))
        this.$set(this.filterObj, 'endDatePicker', moment().format('YYYY-MM-DD'))
      } else {
        this.$set(this.filterObj, 'date', moment().format('DD.MM.YYYY'))
        this.$set(this.filterObj, 'datePicker', moment().format('YYYY-MM-DD'))
      }
      this.getAttendanceObj()
    },
    goNext() {
      var momentObj = moment(this.filterObj.datePicker).add(1, 'days')
      this.$set(this.filterObj, 'date', momentObj.format('DD.MM.YYYY'))
      this.$set(this.filterObj, 'datePicker', momentObj.format('YYYY-MM-DD'))
      this.getAttendanceObj()
    },
    goPrevious() {
      var momentObj = moment(this.filterObj.datePicker).subtract(1, 'days')
      this.$set(this.filterObj, 'date', momentObj.format('DD.MM.YYYY'))
      this.$set(this.filterObj, 'datePicker', momentObj.format('YYYY-MM-DD'))
      this.getAttendanceObj()
    }
  }
}
</script>

<style>

</style>